import type {
  IPgPageBuilder,
  IPgPageBuilderFields,
} from 'types/generated/contentful';

import type { GetServerSidePropsContext } from 'next';

import { useEffect } from 'react';
import { MetaHead } from 'components/MetaHead/MetaHead';
import { ContentfulContent } from 'components/ContentfulContent/ContentfulContent';
import { Header } from 'components/Header/Header';
import { Footer } from 'components/Footer/Footer';

import { getPbPageByUrl } from 'lib/contentful/contentfulClient';
import { slugToUrl } from 'lib/contentful/slugHelper';
import { getPageConfig } from 'lib/pageConfig/pageConfigHelper';
import { usePageConfig } from 'contexts/PageConfigContext';
import { withOptimizelyProps } from 'lib/optimizely';
import { SeaLegalText } from 'components/SeaLegalText/SeaLegalText';

interface PageProps {
  fallback: { [key: string]: any };
  page: IPgPageBuilder | null;
}

/**
 * This is a catch-all-route for nextjs. This means, it attemts
 * to render all routes that are not explicitly defined in the
 * blacklist of getStaticPaths.
 * This also means that if we decide to do AB tests on the index page
 * we need to switch the top level to explicit routing by including all
 * top level pages and siloes as we cannot have an index.tsx and a catchall
 * at the same time.
 * One solution could be a dynamic route [...slug.tsx] plus index.tsx
 */
export default function Page({ page }: PageProps) {
  const { setPageConfig } = usePageConfig();
  const currentPageConfig = getPageConfig(page?.fields as IPgPageBuilderFields);

  useEffect(() => {
    // Set pageConfig for any other components to use
    setPageConfig(currentPageConfig);
    // Just do this once. The page config cannot change afterwards
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!page) return null;

  const fields = page.fields as IPgPageBuilderFields;
  const {
    url,
    title,
    description,
    opengraphTitle,
    opengraphDescription,
    opengraphImage,
    seoJsonLd,
  } = fields;
  const {
    sys: { createdAt, updatedAt },
  } = page!;

  const { includeSEO, markupSchema, minimizeHeaderAndFooter } =
    currentPageConfig;
  const headerStyle = minimizeHeaderAndFooter ? 'minimal' : 'full';
  const footerStyle = headerStyle;

  return (
    <>
      <MetaHead
        url={url}
        title={title}
        description={description}
        ogTitle={opengraphTitle}
        ogDescription={opengraphDescription}
        ogImage={opengraphImage}
        pageJsonLD={seoJsonLd}
        index={includeSEO}
        follow={includeSEO}
        markupSchema={markupSchema}
        datePublished={createdAt}
        dateModified={updatedAt}
      />

      <Header headerStyle={headerStyle} />

      <ContentfulContent page={page} />
      <SeaLegalText showLegalText />
      <Footer footerStyle={footerStyle} />
    </>
  );
}

export const getServerSideProps = withOptimizelyProps(
  async ({ params }: GetServerSidePropsContext) => {
    const url = slugToUrl('/', params!.slug!); // Router guarantees there is a slug unless index page
    const page = await getPbPageByUrl(url, { applyStripSys: true });

    if (!page) {
      return { notFound: true };
    }

    return {
      props: {
        page,
      },
    };
  },
);
