import { Container, Typography, Link } from '@mui/material';
import NextLink from 'next/link';

export const SeaLegalText = ({ showLegalText }: { showLegalText: boolean }) => {
  if (!showLegalText) return null;

  return (
    <Container maxWidth="lg" sx={{ marginTop: '16px' }}>
      <Typography variant="body2" color="textSecondary">
        <b>Sparen Sie über 35% mit Finanzcheck:</b> Vgl. der eff.
        Jahreszinssätze neuer (lt. Bundesbank) mit den über Finanzcheck
        vermittelten Krediten 2020.{' '}
        <Link
          href="/kreditvergleich/"
          component={NextLink}
          color="textSecondary"
          prefetch={false}
          sx={{ textDecoration: 'underline' }}
        >
          Mehr&nbsp;Infos
        </Link>
      </Typography>
      <Typography variant="body2" color="textSecondary" mt={1}>
        <b>0,68% Zinsen:</b> 2/3 aller Kunden erhalten bei einem
        Nettodarlehensbetrag 50.000,00 €; 60 Monate Laufzeit; 7,99% effektiver
        Jahreszins; 7,71% p.a. gebundener Sollzins; 60 mtl. Raten zu je 1.006,93
        €; 60.415,84 € Gesamtbetrag; Vereinigte Volksbank Raiffeisenbank eG,
        Darmstädter Str. 62, 64354 Reinheim. (§17 PAngV)
      </Typography>
    </Container>
  );
};
